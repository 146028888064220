<template>
  <div class="login-bgr">
    <v-container fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12>
          <v-card
            class="mx-auto my-12 pa-4 login-card"
            max-width="450"
            min-width="300"
            elevation="10"
          >
            <div class="d-flex justify-end align-center">
              <LocaleSwitcher color="primary" />
            </div>
            <h1 class="text-center mb-4">{{ $t('locationAlert') }}</h1>
            <i class="fal fa-map-marker-alt icon" />
            <div v-if="!loginFailed">
              <h2 class="text-center mb-16">
                {{ $t('loginV24Sso.loggingIn') }}
              </h2>
            </div>
            <div v-else>
              <h2 class="text-center mb-16">
                {{ $t('loginV24Sso.loginFailed') }}
              </h2>
              <p>
                {{ $t('loginV24Sso.loginFailedDescription') }}
              </p>
              <div class="d-flex justify-center">
                <v-btn href="https://varsling24.net">
                  <v-icon left>fa-arrow-left</v-icon>
                  {{ $t('loginV24Sso.backToV24') }}
                </v-btn>
              </div>
              <div class="d-flex justify-center mt-4">
                <v-btn to="/login" color="primary">
                  {{ $t('loginV24Sso.loginManually') }}
                  <v-icon right>fa-key</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { errLog } from '@/util/logger';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';

export default {
  name: 'V24Sso',

  components: {
    LocaleSwitcher,
  },

  data: () => ({
    loginFailed: false,
    sessionId: '',
    sessionTicket: '',
  }),

  mounted() {
    const simulationMode = this.getQueryParam('sim') === 'yes';
    this.$store.commit('user/setSimulateMode', simulationMode);

    this.sessionId = this.getQueryParam('s');
    this.sessionTicket = this.getQueryParam('ticket');

    if (this.sessionTicket) {
      this.logInWithSessionTicket();
    } else {
      this.logInWithSessionId();
    }
  },

  methods: {
    getQueryParam(param) {
      return this.$route.query[param];
    },

    async logInWithSessionId() {
      this.loginFailed = false;
      try {
        const result = await this.$store.dispatch('user/loginWithSessionId', this.sessionId);
        if (result.status == 200) {
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.setLoginFailed(result);
        }
      } catch (error) {
        this.setLoginFailed(error);
      }
    },

    async logInWithSessionTicket() {
      this.loginFailed = false;
      try {
        const result = await this.$store.dispatch(
          'user/loginWithSessionTicket',
          this.sessionTicket,
        );
        if (result.status == 200) {
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.setLoginFailed(result);
        }
      } catch (error) {
        this.setLoginFailed(error);
      }
    },

    setLoginFailed(error) {
      this.loginFailed = true;
      errLog(error);
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
h2 {
  color: #d32f2f;
}
.login-bgr {
  position: relative;
  height: 100vh;
  background: url('../assets/images/bgr.jpg');
  background-size: cover;
  overflow: auto;
}
.icon {
  font-size: 5em;
  text-align: center;
  display: block;
  padding: 16px 0;
  color: #d32f2f;
}
.login-card {
  background-color: #ffffffdb;
}
</style>
